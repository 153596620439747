<template>
<!-- 我的小组 -->
  <div id="app" style="background: #f4f4f4">
    <div class="main">
      <div class="cson-container clearfix">
        <div class="content">
          <div class="content-head">
            <div class="content-title">
              刷题打卡
              <div class="content-line"></div>
              <span class="content-subtext">分类刷题，更高效</span>
            </div>
          </div>
          <el-tabs type="border-card">
            <el-tab-pane label="动 态">
              <publishComment
                type="inGroup"
                :groupId="groupDetail.id"
              ></publishComment>
            </el-tab-pane>
            <el-tab-pane label="榜 单">
              <div class="rank-panel">
                <div class="rank-box">
                  <div class="rank-box-head">
                    <div class="rank-box-th" style="width: 180px">我的：</div>
                    <div class="rank-box-th">
                      <img class="rank-avatar" :src="userInfo.avatar" />
                      <span class="rank-name">{{ userInfo.username }}</span>
                    </div>
                    <div class="rank-box-th">
                      总刷题数：{{ statistics.exerciseNum }}道
                    </div>
                    <div class="rank-box-th">
                      组内排名：{{
                        inGroupRanking == -1 ? "暂无排名" : inGroupRanking
                      }}
                      <span class="iconfont icon-up"></span>
                    </div>
                  </div>
                </div>
                <div class="rank-box-body">
                  <el-row :gutter="110">
                    <el-col :span="12">
                      <div class="list-box">
                        <div class="list-head">
                          <div class="list-title">
                            总 榜<span>（加入组至今）</span>
                          </div>
                        </div>
                        <el-table
                          :data="totalRankingData"
                          size="mini"
                          style="width: 100%"
                          class="table"
                        >
                          <el-table-column
                            type="index"
                            label="排名"
                            align="center"
                            width="60"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="名称"
                            align="center"
                            width="100"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="questionNum"
                            label="刷题量"
                            align="center"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.questionNum }}
                              <span v-if="scope.row.comparison">
                                <i class="icon iconfont icon-icon-increase"></i>
                              </span>
                              <span v-else>
                                <i class="icon iconfont icon-icon-decrease"></i>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="list-box">
                        <div class="list-head">
                          <div class="list-title">日 榜</div>
                        </div>
                        <el-table
                          :data="dailyRankingData"
                          size="mini"
                          style="width: 100%"
                          class="table"
                        >
                          <el-table-column
                            type="index"
                            label="排名"
                            align="center"
                            width="60"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="名称"
                            align="center"
                            width="100"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="questionNum"
                            label="刷题量"
                            align="center"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.questionNum }}
                              <span v-if="scope.row.comparison">
                                <i class="icon iconfont icon-icon-increase"></i>
                              </span>
                              <span v-else>
                                <i class="icon iconfont icon-icon-decrease"></i>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="list-box">
                        <div class="list-head">
                          <div class="list-title">周 榜</div>
                        </div>
                        <el-table
                          :data="weeklyRankingData"
                          size="mini"
                          style="width: 100%"
                          class="table"
                        >
                          <el-table-column
                            type="index"
                            label="排名"
                            align="center"
                            width="60"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="名称"
                            align="center"
                            width="100"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="questionNum"
                            label="刷题量"
                            align="center"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.questionNum }}
                              <span v-if="scope.row.comparison">
                                <i class="icon iconfont icon-icon-increase"></i>
                              </span>
                              <span v-else>
                                <i class="icon iconfont icon-icon-decrease"></i>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="list-box">
                        <div class="list-head">
                          <div class="list-title">月 榜</div>
                        </div>
                        <el-table
                          :data="monthlyRankingData"
                          size="mini"
                          style="width: 100%"
                          class="table"
                        >
                          <el-table-column
                            type="index"
                            label="排名"
                            align="center"
                            width="60"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="名称"
                            align="center"
                            width="100"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="questionNum"
                            label="刷题量"
                            align="center"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.questionNum }}
                              <span v-if="scope.row.comparison">
                                <i class="icon iconfont icon-icon-increase"></i>
                              </span>
                              <span v-else>
                                <i class="icon iconfont icon-icon-decrease"></i>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="主 题">
              <div class="theme-panel">
                <div class="theme-cells">
                  <div
                    class="theme-cell"
                    v-for="(mainTheme, index) in mainThemes"
                    :key="index"
                  >
                    <div to="" class="theme-cell-title">
                      <el-tag
                        type="warning"
                        effect="plain"
                        size="mini"
                        v-if="mainTheme.top"
                      >
                        最新 </el-tag
                      >{{ mainTheme.title }}
                    </div>
                    <div class="theme-cell-body">
                      <div class="theme-cell-desc">
                        {{ mainTheme.content }}
                      </div>
                      <!-- <el-button type="text" size="mini" class="open-btn"
                        >展开<i class="el-icon-arrow-down el-icon--right"></i
                      ></el-button> -->
                    </div>
                    <div class="theme-cell-foot">
                      <div class="theme-cell-key">
                        <span class="iconfont icon-shijian"></span>2020/02/18
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="mainThemes.length == 0"
                    style="text-align: center; font-size: 20px"
                  >
                    暂无发布主题！
                  </div>
                </div>
                <div class="pagination-block">
                  <el-pagination
                    background
                    prev-text="上一页"
                    next-text="下一页"
                    layout="prev, pager, next"
                    :total="mainThemesTotal"
                    :current-page="mainThemesCurrent"
                    @current-change="getMainThemes"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="aside">
          <div class="aside-top">
            <button type="button" class="my-dk" @click="goToMyClock">
              我的打卡
            </button>
          </div>
          <div class="my-panel">
            <div class="my-panel__hd">
              <img class="my-avatar" :src="groupDetail.avatar" />
              <div class="my-name">{{ groupDetail.name }}</div>
            </div>
            <div class="my-panel__bd">
              <el-row>
                <el-col :span="8">
                  <div class="my-grid">
                    <div class="my-val">{{ groupDetail.memberNums }}</div>
                    <div class="my-label">全部成员</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="my-grid">
                    <div class="my-val">{{ groupDetail.clockAvg }}</div>
                    <div class="my-label">平均刷题</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="my-grid">
                    <div class="my-val">{{ groupDetail.groupRank }}</div>
                    <div class="my-label">刷题排名</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="aside-panel" style="padding: 10px 15px" v-if="isInGroup">
            <template>
              <el-button
                class="fb-theme"
                @click="dialogVisible = true"
                style="margin-bottom: 12px"
                v-if="groupDetail.duty == 1"
                >发布主题</el-button
              >
              <el-button
                class="fb-theme"
                @click="quitGroup"
                style="background: #e02020; color: #fff"
              >
                退出小组
              </el-button>
            </template>
          </div>
          <div class="aside-panel" style="padding: 10px 15px" v-else>
            <template>
              <el-button class="fb-theme" @click="joinGroup"
                >加入小组</el-button
              >
            </template>
          </div>
          <!--  -->
          <el-dialog
            :visible.sync="dialogVisible"
			 :close-on-click-modal="false"
            width="650px"
            :show-close="false"
            class="theme-dialog"
          >
            <div class="dialog-tt" slot="title">发布主题</div>
            <el-form
              ref="form"
              :model="form"
              label-width="70px"
              class="theme-form"
            >
              <el-form-item label="标题">
                <el-input v-model="form.title" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="内容">
                <el-input
                  type="textarea"
                  :rows="10"
                  v-model="form.content"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-foot">
              <el-button
                type="info"
                size="small"
                class="btn text"
                @click="dialogVisible = false"
                >取 消
              </el-button>
              <el-button
                type="warning"
                size="small"
                class="btn warn"
                @click="publishNotice"
                >发 布
              </el-button>
            </div>
          </el-dialog>
          <!--  -->
          <div class="aside-panel group-profile">
            <div class="aside-head">
              <div class="aside-title aside-title-border">小组简介</div>
              <el-button
                type="text"
                size="mini"
                class="share-links"
                @click="copyLinks"
                >分享链接</el-button
              >
            </div>
            <div class="group-info">
              <div class="group-cell">
                <div class="group-cell__hd">简介：</div>
                <div class="group-cell__bd">
                  {{ groupDetail.message }}
                </div>
              </div>
              <div class="group-cell">
                <div class="group-cell__hd">目标：</div>
                <div class="group-cell__bd">
                  {{ groupDetail.targetDay }}天 {{ groupDetail.targetNum }}题
                </div>
              </div>
              <div class="group-cell">
                <div class="group-cell__hd">创建时间：</div>
                <div class="group-cell__bd">{{ groupDetail.createTime }}</div>
              </div>
            </div>
            <div
              class="group-footer"
              v-if="groupDetail && groupDetail.duty == 1"
            >
              <el-button
                type="warning"
                plain
                size="mini"
                class="group-edit"
                @click="groupEdit"
              >
                编 辑
              </el-button>
              <createGroupComponents
                type="edit"
                :data="groupDetail"
                :visible="editVisible"
                @changeCreateVisible="(value) => (editVisible = value)"
                @updateGroup="updateGroup"
              ></createGroupComponents>
            </div>
          </div>
          <div class="aside-course">
            <router-link to=""
              ><img src="../../assets/punch/course1.png"
            /></router-link>
          </div>
          <div class="aside-course">
            <router-link to=""
              ><img src="../../assets/punch/course2.png"
            /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publishComment from "./publishComment.vue";
import createGroupComponents from "./createGroup/createGroup";
import {
  getGroupDetail,
  publishNotice,
  getMainThemes,
  existInGroup,
  joinGroup,
  quitGroup,
  getRankingList,
  getClockStatistics,
  getInGroupRanking,
  createGroup,
} from "../../service/punchClock.js";
import { mapState } from "vuex";
export default {
  name: "myGroup",
  components: { publishComment, createGroupComponents },
  data() {
    return {
      dialogVisible: false,

      textarea1: "",
      region: "",
      textarea: "",

      input: "",
      form: {
        clockInGroupId: "",
        title: "",
        content: "",
      },

      //
      groupDetail: {},
      isInGroup: false,
      mainThemes: [],
      mainThemesTotal: 0,
      mainThemesCurrent: 1,
      statistics: {},
      inGroupRanking: 0,
      totalRankingData: [],
      dailyRankingData: [],
      weeklyRankingData: [],
      monthlyRankingData: [],
      editVisible: false,
    };
  },
  methods: {
    getGroupDetail() {
      getGroupDetail(this.$route.query.groupId).then((res) => {
        this.groupDetail = res.result;
        this.form.clockInGroupId = res.result.id;
        document.title = `${this.groupDetail.name} - 专注北美算法面试 - CSON`
      });
    },
    updateGroup() {
      this.getGroupDetail();
      this.getMainThemes();
      this.checkInGroup();
      this.getRankingListData();
    },
    goToMyClock() {
      this.$router.push("/punchClockInit");
    },
    copyLinks() {
      var input = document.createElement("input"); // 直接构建input
      input.value = this.groupDetail.link; // 设置内容 需要复制的内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功!");
    },
    publishNotice() {
      if (this.form.title == "") {
        return this.$message.error("请填写主题标题！");
      }
      if (this.form.content == "") {
        return this.$message.error("请填写主题内容！");
      }
      publishNotice(this.form).then((res) => {
        if (res.success) {
          this.$message.success("发布主题成功！");
          this.dialogVisible = false;
          this.getMainThemes();
          this.form.title = "";
          this.form.content = "";
        }
      });
    },
    getMainThemes(page) {
      getMainThemes(this.$route.query.groupId, page ? page : 1).then((res) => {
        if (res.success) {
          this.mainThemes = res.result.records;
          this.mainThemesTotal = res.result.total;
        }
      });
    },
    checkInGroup() {
      existInGroup(this.$route.query.groupId).then((res) => {
        this.isInGroup = res.result;
      });
    },
    joinGroup() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login")
        return 
      }
      if (!this.groupDetail.isOpen) {
        this.$prompt("请输入口令", "提示", {
          type: "info",
        }).then(({ value }) => {
          joinGroup(value, this.groupDetail.id)
            .then((res) => {
              if (res.success) {
                this.$message.success("加入小组成功！");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.$message.error("口令错误！");
            });
        });
      } else {
        this.$confirm("确认加入此小组？", "提示", {
          type: "info",
        }).then(() => {
          joinGroup("", this.groupDetail.id).then((res) => {
            if (res.success) {
              this.$message.success("加入小组成功！");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.$message.error(res.message);
            }
          });
        });
      }
    },
    quitGroup() {
      this.$confirm("确认退出此小组？", "提示", {
        type: "info",
      }).then(() => {
        quitGroup(this.groupDetail.id).then((res) => {
          if (res.success) {
            this.$message.success("退出小组成功！");
            setTimeout(() => {
              this.$router.push("/punchClock");
            }, 2000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    getRankingListData() {
      getClockStatistics().then((res) => {
        this.statistics = res.result;
      });
      getRankingList("total", this.$route.query.groupId).then((res) => {
        this.totalRankingData = res.result;
      });
      getRankingList("day", this.$route.query.groupId).then((res) => {
        this.dailyRankingData = res.result;
      });
      getRankingList("week", this.$route.query.groupId).then((res) => {
        this.weeklyRankingData = res.result;
      });
      getRankingList("month", this.$route.query.groupId).then((res) => {
        this.monthlyRankingData = res.result;
      });
      getInGroupRanking(this.$route.query.groupId).then((res) => {
        this.inGroupRanking = res.result;
      });
    },
    groupEdit() {
      this.editVisible = true;
    },
  },
  mounted() {
    console.log(this.$route.query.groupId);
    this.getGroupDetail();
    this.getMainThemes();
    this.checkInGroup();
    this.getRankingListData();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>

<style lang="css" scoped>
.content {
  width: 890px;
  float: left;
}

.aside {
  width: 300px;
  float: right;
}

.content-head {
  padding: 20px 0;
}

.content-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #0075f6;
  line-height: 27px;
}

.content-subtext {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

.content-line {
  width: 2px;
  height: 28px;
  margin: 0 13px;
  background: rgba(136, 148, 171, 0.2);
}

.comment {
  position: relative;
}

.comment-mask {
  position: absolute;
  width: 100%;
  padding: 15px 40px 15px 20px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
}

.comment-title {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
  margin-bottom: 10px;
}

.comment-question {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  margin-bottom: 12px;
}

.comment-foot {
  display: flex;
  align-items: center;
}

.comment-number {
  color: #fff;
  font-size: 12px;
}

.comment-user {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  flex: 1;
}

.comment-name {
  margin-right: 10px;
}

.comment-avatar {
  font-size: 0;
  margin-right: 14px;
}

.comment-avatar img {
  height: 18px;
  width: 18px;
  border-radius: 100%;
}

.comment-lt {
  float: left;
  width: 458px;
}

.comment-rt {
  float: right;
  width: 422px;
}

.comment-img {
  font-size: 0;
}

.comment-mask-all {
  height: 100%;
  padding: 20px 40px 20px 15px;
}

.comment-mask-all .comment-avatar {
  margin-right: 5px;
}

.comment-mask-all .comment-name {
  margin-right: 5px;
}

.comment-mask-all .comment-question {
  font-size: 12px;
  margin-bottom: 20px;
}

.comment-tag {
  font-size: 12px;
  color: #ffcf2d;
  line-height: 18px;
  padding-left: 5px;
  border-left: 1px solid #ffcf2d;
  margin-bottom: 15px;
}

.comment-mask-samll {
  height: 100%;
  padding: 50px 20px 10px 5px;
}

.comment-mask-samll .comment-question {
  margin-bottom: 35px;
}

.comment-small {
  margin-bottom: 10px;
}

.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}

.panel {
  padding: 0 20px;
}

.panel-head {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bread-tab {
  display: inline-block;
  padding: 0 2px;
}

.bread-tab.active {
  color: #fa6400;
}

.bread-tabs-head {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
}

.bread-tabs {
  float: right;
  margin-right: 40px;
}

.left {
  float: left;
}

.team-img {
  font-size: 0;
}

.team-cell__hd {
  width: 200px;
  margin-right: 17px;
  float: left;
}

.team-cell {
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.team-cell-title {
  color: #34495e;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
}

.push-time {
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  font-size: 12px;
  margin-left: 12px;
}

.team-cell-desc {
  font-size: 14px;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 25px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
  margin-left: 217px;
}

.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__block .el-textarea /deep/ .el-textarea__inner {
  background: rgba(235, 236, 241, 0.43);
  border-radius: 2px;
  border: 1px solid #979797;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}

.btn-default {
  color: #34495e;
  background-color: rgba(136, 148, 171, 0.4);
}

.btn-warn {
  color: #fff;
  background-color: rgba(250, 100, 0, 1);
}

.team-comment__ava {
  font-size: 0;
}

.team-comment {
  display: flex;
}

.team-comment__ava img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.team-comment__hd {
  width: 24px;
  margin-right: 6px;
}

.team-comment__bd {
  flex: 1;
}

.team-comment__name {
  font-size: 14px;
  font-weight: bold;
  color: #34495e;
  line-height: 20px;
}

.team-comment__jj {
  font-weight: 400;
  color: #898989;
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 5px;
}

.team-comment__desc {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 6px;
}

.team-comment__time {
  font-size: 12px;
  color: #898989;
  line-height: 18px;
  margin-bottom: 8px;
}

.team-comment__number {
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
  font-size: 12px;
}

.el-pagination /deep/ .el-pager li {
  height: 21px;
  line-height: 21px;
  min-width: 22px;
  font-size: 12px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.el-pagination.is-background /deep/ .el-pager li:not(.disabled).active {
  background-color: rgba(250, 100, 0, 1);
}

.el-pagination /deep/ button,
.el-pagination /deep/ span {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

.el-pagination .last {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.el-pagination .first {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-block {
  text-align: center;
  padding: 25px 0;
}

.my-dk {
  line-height: 24px;
  font-size: 14px;
  background: #fa6400;
  border-radius: 16px;
  width: 86px;
  text-align: center;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.aside-top {
  text-align: right;
  padding: 20px 0;
}

.aside-lt {
  float: left;
}

.aside-title {
  color: #fa6400;
  line-height: 22px;
  font-size: 14px;
}

.aside-more {
  font-weight: 500;
  color: #8894ab;
  line-height: 22px;
  font-size: 12px;
  text-decoration: underline;
}

.aside-rt {
  float: right;
}

.aside-panel {
  background-color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  border-radius: 4px;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
  margin-left: 10px;
}

.el-table--mini /deep/ td,
.el-table--mini /deep/ th {
  padding: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
}

.el-table--mini /deep/ th {
  background: rgba(0, 117, 246, 0.1);
  color: #34495e;
}

.el-table--mini /deep/ .el-table__row td:first-child .cell div {
  width: 16px;
  height: 16px;
  background: #b6daff;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 0 auto;
}

.el-table--mini /deep/ .el-table__row:first-child td:first-child .cell div {
  background: #ff5a43;
}

.el-table--mini /deep/ .el-table__row:nth-child(2) td:first-child .cell div {
  background: #44d7b6;
}

.el-table--mini /deep/ .el-table__row:nth-child(3) td:first-child .cell div {
  background: #f8c51c;
}

.el-table--mini /deep/ .el-table__body-wrapper {
  padding: 10px 0;
}

.el-table::before {
  background-color: transparent;
}

.aside-course {
  font-size: 0;
  margin-bottom: 10px;
}

.aside-course img {
  width: 100%;
  height: 130px;
}

.fb-theme {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #34495e;
  width: 100%;
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}

.my-panel {
  background-color: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
}

.my-panel__hd {
  border-bottom: 1px dashed rgba(136, 148, 171, 0.2);
  text-align: center;
  padding-bottom: 10px;
}

.my-avatar {
  background-color: #fa6400;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  line-height: 48px;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 10px;
}

.my-name {
  color: #34495e;
  font-size: 14px;
  line-height: 24px;
}

.my-val {
  font-size: 16px;
  color: #fa6400;
  text-align: center;
  line-height: 18px;
}

.my-label {
  font-size: 12px;
  color: #34495e;
  text-align: center;
  line-height: 18px;
}

.my-grid {
  padding: 10px 0;
}

.group-profile .aside-head {
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
}

.aside-title-border {
  color: #34495e;
  padding-left: 10px;
  position: relative;
}

.aside-title-border::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 16px;
  background: #fa6400;
  border-radius: 2px;
}

.share-links {
  color: #34495e;
  text-decoration: underline;
}

.group-cell {
  font-size: 12px;
  line-height: 16px;
  padding: 12px 0;
  display: flex;
  align-items: center;
}

.group-cell__hd {
  color: #34495e;
}

.group-cell__bd {
  color: #34495e;
  flex: 1;
  opacity: 0.7;
}

.group-edit {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
  padding: 4px 12px;
}

.group-footer {
  text-align: right;
  padding: 6px 0 34px;
}

.group-info {
  padding: 12px 0;
}

.dynamic-form-item .el-button {
  width: 90px;
}

.dynamic-form-item /deep/ .el-textarea__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item /deep/ .el-input__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item + .dynamic-form-item {
  margin-top: 10px;
}

.push-btn {
  background-color: #fa6400;
  border-color: #fa6400;
  color: #fff;
  float: right;
}

.dynamic-form-item .el-select {
  width: 125px;
}

.dynamic-form-item /deep/ .el-select .el-input__inner {
  background-color: #fff;
}

.bg-line {
  margin: 0 -40px;
  height: 20px;
  background: #f8f8f8;
}

.dynamic-form {
  padding: 25px 40px 35px;
  border-radius: 4px;
}

.exp-btn {
  width: 80px !important;
  font-size: 14px !important;
  background-color: rgba(235, 236, 241, 0.6);
  border: 0;
  color: #000000;
  opacity: 0.85;
}

.exp-btn .iconfont {
  font-size: 14px;
  margin-right: 4px;
}

.el-tabs {
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  border-radius: 4px;
  border: 0;
}

.el-tabs /deep/ .el-tabs__header {
  background-color: #fff;
  border-bottom: 0;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
}

.el-tabs /deep/ .el-tabs__header .el-tabs__item {
  border: 0;
  margin: 0;
  flex: 1;
  text-align: center;
  height: 48px;
  line-height: 48px;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
}

.el-tabs /deep/ .el-tabs__header .el-tabs__item:hover {
  color: rgba(250, 100, 0, 1);
}

.el-tabs /deep/ .el-tabs__header .el-tabs__item.is-active {
  color: rgba(250, 100, 0, 1);
  background-color: rgba(250, 100, 0, 0.1);
}

.el-tabs /deep/ .el-tabs__header .el-tabs__nav {
  display: flex;
  float: unset;
}

.el-tabs /deep/ .el-tabs__content {
  padding: 0;
}

.cson-container {
  padding-bottom: 25px;
}

.theme-cell-title {
  font-size: 14px;
  color: #000000;
  display: block;
  margin-bottom: 10px;
}

.theme-cell-title .el-tag {
  margin-right: 6px;
}

.theme-cell-key {
  display: inline-block;
  font-size: 12px;
  color: #000000;
  opacity: 0.2;
}

.theme-cell-key .iconfont {
  font-size: 14px;
  margin-right: 6px;
}

.theme-cell-desc {
  font-size: 14px;
  color: #999999;
  line-height: 16px;
}

.theme-cell-body {
  padding-right: 110px;
  position: relative;
  margin-bottom: 15px;
}

.open-btn {
  position: absolute;
  right: 30px;
  top: 0;
  padding: 3px 10px;
  color: #fa6400;
}

.theme-cell {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.theme-cells {
  padding: 25px 0;
}

.rank-box {
  padding: 0 25px;
}

.rank-box-head {
  background-color: #f0f7ff;
  border-radius: 4px;
  padding: 14px 26px;
}

.rank-name {
  color: #0075f6;
  text-decoration: underline;
  font-size: 12px;
  vertical-align: top;
}

.icon-up {
  color: #ff5a43 !important;
}

.bread-tab-line {
  display: inline-block;
}

.icon-down1 {
  color: #44d7b6 !important;
}

.rank-box-th {
  color: #34495e;
  font-size: 14px;
  line-height: 26px;
  display: inline-block;
  min-width: 160px;
}

.rank-avatar {
  background-color: #fa6400;
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  text-align: center;
  font-size: 10px;
  color: #fff;
  margin-right: 8px;
  vertical-align: top;
}

.rank-box-body {
  padding: 30px 60px 0;
}

.list-box {
  margin-bottom: 20px;
  min-height: 400px;
}

.list-title {
  font-size: 14px;
  color: #fa6400;
}

.list-title span {
  color: #c6c6c6;
  font-size: 12px;
}

.list-head {
  padding: 17px 17px 6px;
}

.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}

.warn {
  background-color: #fa6400 !important;
}

.grey {
  background-color: #8894ab !important;
}

.dialog-foot .el-button + .el-button {
  margin-left: 20px;
}

.dialog-tt {
  position: relative;
  padding-left: 12px;
  color: #34495e;
  font-size: 18px;
}

.dialog-tt::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}

.theme-form /deep/ .el-form-item {
  margin-bottom: 12px;
}

.theme-dialog /deep/ .el-dialog__body {
  padding: 0 40px;
}

.theme-dialog /deep/ .el-dialog__header {
  padding: 25px 45px;
}

.team-cell:hover {
  cursor: pointer;
}

.theme-cell:hover {
  cursor: pointer;
}

.rank-panel {
  padding: 20px;
}

.theme-panel {
  padding: 0 20px;
}
.icon-icon-increase {
  color: #ff3b30;
}
.icon-icon-decrease {
  color: #34c759;
}
</style>
